import React from 'react';

const Header = () => {
    return (
        <>
            <h1>The cat app</h1>
            <h2>Here you can find lots of cats 🐈 🧶</h2>
        </>
    );
};

export default Header;