import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { usePostVoteMutation } from '../store/catApi';


const Card = ({url, alt, id}) => {
    const dispatch = useDispatch();
    const [postVote] = usePostVoteMutation();

    const handleClick = () => {
        postVote({ image_id: id, value: 1 });
    };

    return (
        <>
           <StyledImg src={url} alt={alt} onClick={handleClick}/>
        </>
    );
};

const StyledImg = styled.img`
    width: 200px;  
    height: 200px;
    object-fit: cover;
    margin: 1rem;
`;

export default Card;