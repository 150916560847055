import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const BASE_URL = 'https://api.thecatapi.com/v1/';
const API_KEY = 'live_6Q1psSSzb8z0mdmdg15EyNFUPqXPzoCsQLctDsw2uvNmULeoH5AwkRpKY13qW37p'; 

export const catApi = createApi({
  reducerPath: 'catApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: BASE_URL, 
    prepareHeaders: (headers) => {
        headers.set('x-api-key', `${API_KEY}`);
        headers.set('Content-Type', 'application/json');
        return headers;
      },
}),
  endpoints: (builder) => ({
    fetchCats: builder.query({
      query: ({ page = 1, limit = 10 }) => `images/search?page=${page}&limit=${limit}`,
    }), 

    postVote: builder.mutation({
      query: ({ image_id, value }) => ({
        url: 'votes/',
        method: 'POST',
        body: {
          image_id,
          value
        }
      })
    })
  }),
});

export const { useFetchCatsQuery, usePostVoteMutation } = catApi;
export default catApi;
