import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCats, setCurrentPage } from '../store/cats-slice';
import Card from './Card';
import { useFetchCatsQuery } from '../store/catApi';
import Paginator from './Paginator';
import styled from 'styled-components';

const List = () => {  
  const [currentPage, setCurrentPage] = useState(0);
  const { data: cats, isLoading, isError } = useFetchCatsQuery({ page: currentPage, limit: 10 });

  return (
    <>
      {isLoading ? (
        <p>Loading...</p>
      ) : isError?
      (<p>There was an error...</p>) : cats? (
        <div>
          {
        cats.map((cat) => (<Card url={cat.url} alt={cat.id} key={cat.id} id={cat.id}/>))
          }
        </div>
      ): null}
      <Paginator 
      current_page={currentPage}
      changePage={setCurrentPage}/>
    </>
  );
};

const StyledList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export default List;
