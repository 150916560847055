import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  cats: [],
  loading: false,
  currentPage: 1,
};

const catSlice = createSlice({
  name: 'cats',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
});

export const { setCurrentPage } = catSlice.actions;
export default catSlice;

  