import './App.css';
import Header from './components/Header';
import List from './components/List';

import { useSelector } from 'react-redux';

function App7() {

  return (
    <div className="App">
      <Header />
      <List/>
    </div>
  );
}

export default App7;